/* eslint-disable no-self-compare */

import _ from 'lodash'
import { getRawBottleFeeVariantIdFromProduct } from './product-utils'
import getPrice from './getPrice'

export function convertRawItemsToLineItemsToQuantity(
  rawItems,
  ignoreBottleFeeProducts = true
) {
  if (rawItems == null) {
    return []
  }

  return rawItems.map(currItem => {
    // if (
    //   ignoreBottleFeeProducts &&
    //   BOTTLE_DEPOSITS.includes(currItem.variant_id)
    // ) {
    //   continue
    // }
    //
    // let bottleFeeVariantId = null
    //
    // if (
    //   currItem.properties != null &&
    //   currItem.properties._bottleFeeVariantId != null
    // ) {
    //   bottleFeeVariantId = currItem.properties._bottleFeeVariantId
    // }

    return {
      lineItemId: currItem.id,
      quantity: currItem.quantity,
    }
  })
}

export function getItemsToUpdate(prevItems, currItems) {
  // In updates case, both previousItems and currentItems must have the same length
  // If they are not equal. Spit error and return.
  if (prevItems.length !== currItems.length) {
    alert('We cannot update your cart there was an issue with your items.')
    return []
  }

  // Iterate through each.
  const toUpdateItems = []
  for (let i = 0; i < prevItems.length; i++) {
    const prevItem = prevItems[i]
    const currItem = currItems[i]

    if (prevItem.id !== currItem.id) {
      // This should never be the case. Error and return.
      alert('We cannot update your cart there was an issue with your items.')
      return []
    }

    // Check if the new quantity is usable.
    if (isNaN(parseInt(currItem.quantity, 10)) || currItem.quantity < 0) {
      alert(
        'We cannot update your cart there was an issue with your items. Please check your items in your cart.'
      )
      return []
    }

    // Check if they are the same, if they are we just continue next.
    if (prevItem.quantity === currItem.quantity) {
      continue
    }

    if (_.includes(currItem.collections ?? [], 'bottle-fee')) {
      return []
    }

    // New quantity is good, lets add it in to our list of updates.
    toUpdateItems.push({
      id: currItem.id,
      quantity: parseInt(currItem.quantity, 10),
    })
  }

  if (toUpdateItems.length === 0) {
    return
  }

  // Get all item updates for specifically bottle fees.
  const toUpdateBottleFees = getToUpdateBottleFees(prevItems, currItems)

  return [...toUpdateItems, ...toUpdateBottleFees]
}

export const getItemsToRemove = (prevItems, currItems, indexOfItem) => {
  if (prevItems.length !== currItems.length) {
    return []
  }

  if (
    indexOfItem == null ||
    indexOfItem < 0 ||
    indexOfItem >= currItems.length
  ) {
    return []
  }

  // Check to see if it exists.
  const currItemToRemove = currItems[indexOfItem]
  if (currItemToRemove == null) {
    return []
  }

  // Error if the user tries to remove a bottle fee item specifically.
  if (currItemToRemove.isBottleFeeItem) {
    return []
  }

  // Create a copy of our current cart with the removed item to be removed
  const copyCurrItems = currItems.filter(item => item !== currItemToRemove)

  // Given this copy, we calculate the new bottle fees to update since we removed the item
  const toUpdateBottleFees = getToUpdateBottleFees(prevItems, copyCurrItems)

  // Return a list of items with quantity to be zero and bottle fee updates.
  return [
    {
      id: currItemToRemove.id,
      quantity: 0,
    },
    ...toUpdateBottleFees,
  ]
}

export const getToUpdateBottleFees = (oldItems, newItems) => {
  const oldItemsBottleFees = getBottleFeeUpdatesFromCart(oldItems)
  const newItemsBottleFees = getBottleFeeUpdatesFromCart(newItems)

  // We have the following now:
  /**
    newItemsBottleFees = [{
      {bottleFeeEncodedVariantIdGid}: {quantity}
   }]
   We want to also get there equivalent line items which have these
   bottleFeeEncodedVariantIdGid as their encodedVariantIdGid so we know what
   their line item ID is.
   We get this by going over newItems since it has all the line items with
   their encodedVariantIdGid
   */

  const bottleFeeEncodedGidToLineItemIdMapping = {}
  newItems.forEach(item => {
    if (!item.isBottleFeeItem) {
      return
    }

    bottleFeeEncodedGidToLineItemIdMapping[item.encodedVariantIdGid] = item.id
  })

  // We opt to take the newItemsBottleFees as the source of truth for bottle
  // fees.
  // If we see that a bottle fee does not exist in newItemsBottleFees in
  // oldItemsBottleFees, we add it to be zero.
  const toUpdateBottleFees = _.map(
    newItemsBottleFees,
    (quantity, bottleFeeGid) => {
      const bottleFeeLineItemId =
        bottleFeeEncodedGidToLineItemIdMapping[bottleFeeGid]

      if (bottleFeeLineItemId == null) {
        return null
      }

      return {
        id: bottleFeeLineItemId,
        quantity,
      }
    }
  )

  // Now we have the array, lets push ones to be zero if it
  // does not exist in newItemsBottleFees
  _.forEach(oldItemsBottleFees, (quantity, bottleFeeGid) => {
    const bottleFeeLineItemId =
      bottleFeeEncodedGidToLineItemIdMapping[bottleFeeGid]

    if (bottleFeeLineItemId == null) {
      return
    }

    if (newItemsBottleFees[bottleFeeGid] == null) {
      // It does not exist now, lets update it to be zero
      toUpdateBottleFees.push({
        id: bottleFeeLineItemId,
        quantity: 0,
      })
    }
  })

  return toUpdateBottleFees
}

export const convertRawItemsToItems = rawItems => {
  if (rawItems == null) {
    return []
  }

  return rawItems.map(lineItem => {
    const itemUnitPrice = _.get(lineItem, 'variant.price')
    const itemQuantity = _.get(lineItem, 'quantity')
    const itemTotalPrice = getPrice(
      parseFloat(itemUnitPrice ?? '0') * itemQuantity
    )
    const encodedVariantIdGid = _.get(lineItem, 'variant.id')

    const collections = _.get(lineItem, 'variant.product.collections', []).map(
      collection => collection.handle
    )

    const isBottleFeeItem = _.includes(collections, 'bottle-fee')

    const optionalBottleFeeRawVariantId = getRawBottleFeeVariantIdFromLineItem(
      lineItem
    )

    let bottleFeeGid = null
    if (
      optionalBottleFeeRawVariantId != null &&
      optionalBottleFeeRawVariantId !== ''
    ) {
      bottleFeeGid = getEncodedBottleFeeGid(optionalBottleFeeRawVariantId)
    }

    let variantTitle = _.get(lineItem, 'variant.title')

    if (variantTitle === 'Default Title') {
      variantTitle = null
    }

    return {
      id: lineItem.id,
      title: lineItem.title,
      imgSrc: lineItem?.variant?.image?.src,
      unitPrice: itemUnitPrice,
      quantity: itemQuantity,
      totalPrice: itemTotalPrice,
      collections,
      bottleFeeGid,
      isBottleFeeItem,
      encodedVariantIdGid,
      variantTitle,
    }
  })
}

export function getRawBottleFeeVariantIdFromLineItem(lineItem) {
  const product = _.get(lineItem, 'variant.product')

  return getRawBottleFeeVariantIdFromProduct(product)
}

export const getBottleFeeUpdatesFromCart = cartItems => {
  const toUpdateBottleFeeVariantsToQuantity = {}

  cartItems.forEach(currItem => {
    if (currItem.bottleFeeGid != null) {
      if (toUpdateBottleFeeVariantsToQuantity[currItem.bottleFeeGid] == null) {
        toUpdateBottleFeeVariantsToQuantity[currItem.bottleFeeGid] = 0
      }

      toUpdateBottleFeeVariantsToQuantity[currItem.bottleFeeGid] += parseInt(
        currItem.quantity,
        10
      )
    }
  })

  return toUpdateBottleFeeVariantsToQuantity
}

export const getEncodedBottleFeeGid = rawVariantId => {
  if (rawVariantId == null || rawVariantId === '') {
    return null
  }

  return btoa(`gid://shopify/ProductVariant/${rawVariantId}`)
}

export const getBottleFeePriceFromItems = items => {
  const bottleFeeItems = items.filter(item => item.isBottleFeeItem)

  return bottleFeeItems.reduce((total, currItem) => {
    return total + parseFloat(currItem.totalPrice)
  }, 0)
}

export function deepEqual(a, b) {
  if (a === b) return true

  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (a.constructor !== b.constructor) return false

    var length, i, keys
    if (Array.isArray(a)) {
      length = a.length
      if (length !== b.length) return false
      for (i = length; i-- !== 0; ) if (!deepEqual(a[i], b[i])) return false
      return true
    }

    if (a.constructor === RegExp)
      return a.source === b.source && a.flags === b.flags
    if (a.valueOf !== Object.prototype.valueOf)
      return a.valueOf() === b.valueOf()
    if (a.toString !== Object.prototype.toString)
      return a.toString() === b.toString()

    keys = Object.keys(a)
    length = keys.length
    if (length !== Object.keys(b).length) return false

    for (i = length; i-- !== 0; )
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false

    for (i = length; i-- !== 0; ) {
      var key = keys[i]

      if (!deepEqual(a[key], b[key])) return false
    }

    return true
  }

  // true if both NaN, false otherwise
  return a !== a && b !== b
}
