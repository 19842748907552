import React, { useState } from 'react'
import NotificationContext from '../context/NotificationContext'

const NotificationProvider = ({ children }) => {
  const [addedItem, updateAddedItem] = useState(null)

  const addItem = item => {
    updateAddedItem({ ...item, isClosed: false })
  }

  const closeCurrentItem = () => {
    updateAddedItem({ ...addedItem, isClosed: true })
  }

  const clearItem = () => {
    updateAddedItem(null);
  }

  return (
    <NotificationContext.Provider
      value={{
        addedItem,
        addItem,
        closeCurrentItem,
        clearItem,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
export default NotificationProvider
