/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "@fontsource/source-sans-pro"
import "@fontsource/source-sans-pro/300.css"
import "./src/scss/index.scss"
