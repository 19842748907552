/* eslint-disable default-case */
/* eslint-disable no-template-curly-in-string */

import _ from 'lodash'

export function getRawBottleFeeVariantIdFromProduct(product) {
  return getMetafieldFromProduct(product, 'info', 'bottle_fee_variant_id')
}

export function getMetafieldsFromProduct(product) {
  return _.get(product, 'metafields', [])
}

export function getMetafieldFromProduct(product, namespace, key) {
  if (product == null || !namespace || !key) {
    return null
  }

  const metafields = getMetafieldsFromProduct(product)
  return _.get(
    metafields.filter(
      metafield => metafield.namespace === namespace && metafield.key === key
    ),
    '0.value'
  )
}

export function shopifyFormatMoney(cents, format) {
  if (typeof cents === 'string') {
    cents = cents.replace('.', '')
  }
  let value = ''
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/
  const formatString = format || '${{amount}}'

  function formatWithDelimiters(number, precision, thousands, decimal) {
    thousands = thousands || ','
    decimal = decimal || '.'

    if (isNaN(number) || number === null) {
      return 0
    }

    number = (number / 100.0).toFixed(precision)

    var parts = number.split('.')
    var dollarsAmount = parts[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      '$1' + thousands
    )
    var centsAmount = parts[1] ? decimal + parts[1] : ''

    return dollarsAmount + centsAmount
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2)
      break
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0)
      break
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',')
      break
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',')
      break
    case 'amount_no_decimals_with_space_separator':
      value = formatWithDelimiters(cents, 0, ' ')
      break
    case 'amount_with_apostrophe_separator':
      value = formatWithDelimiters(cents, 2, "'")
      break
  }

  return formatString.replace(placeholderRegex, value)
}
