// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-page-js": () => import("./../../../src/templates/404Page.js" /* webpackChunkName: "component---src-templates-404-page-js" */),
  "component---src-templates-calendar-page-js": () => import("./../../../src/templates/CalendarPage.js" /* webpackChunkName: "component---src-templates-calendar-page-js" */),
  "component---src-templates-cart-page-js": () => import("./../../../src/templates/CartPage.js" /* webpackChunkName: "component---src-templates-cart-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-shop-page-js": () => import("./../../../src/templates/ShopPage.js" /* webpackChunkName: "component---src-templates-shop-page-js" */)
}

