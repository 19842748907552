import React, { useEffect, useState } from 'react'
import UserContext from '../context/UserContext'
import { useCookies } from 'react-cookie'
import _ from 'lodash'

const UserProvider = ({ children }) => {
  const initialUserState = {
    isLoading: true,
    isLoggedIn: false,
  }
  const cookieName = 'grizzli-gatsby-user-session'
  const [cookies, setCookie] = useCookies([cookieName])
  const [user, updateUser] = useState(initialUserState)

  const userCookie = _.get(cookies, cookieName)

  useEffect(() => {
    const isUserLoggedIn = _.get(userCookie, 'isUserLoggedIn')

    if (userCookie == null || userCookie === '' || !isUserLoggedIn) {
      updateUser(prevState => {
        return {
          ...prevState,
          isLoading: false,
          isLoggedIn: false,
        }
      })
      return
    }

    // User is logged in.
    updateUser(prevState => {
      return {
        ...prevState,
        isLoading: false,
        isLoggedIn: true,
      }
    })
  }, [userCookie])

  return (
    <UserContext.Provider value={{ user, setCookie }}>
      {children}
    </UserContext.Provider>
  )
}
export default UserProvider
