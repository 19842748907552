import fetch from 'isomorphic-fetch'
import React, { useState, useEffect, useRef } from 'react'
import Client from 'shopify-buy/index.unoptimized.umd'
import {
  checkoutAddMutation,
  checkoutQuery,
  checkoutRemoveMutation,
  checkoutUpdateMutation,
} from '../utils/queries'

import Context from '~/context/StoreContext'
import defaultResolver from '../utils/defaultResolver'
import handleCheckoutMutation from '../utils/handleCheckoutMutation'
import { getEncodedBottleFeeGid } from '../utils/cart-utils'
import { useCookies } from 'react-cookie'
import getSecondsSinceExpiration from '../utils/getSecondsSinceExpiration'

const client = Client.buildClient(
  {
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    domain: process.env.GATSBY_SHOP_DOMAIN,
  },
  fetch
)

const getCheckoutById = (client, checkoutId) => {
  return client.graphQLClient
    .send(checkoutQuery, {
      id: checkoutId,
    })
    .then(defaultResolver('node'))
    .then(checkout => {
      if (!checkout) {
        return null
      }

      return client.graphQLClient
        .fetchAllPages(checkout.lineItems, { pageSize: 250 })
        .then(lineItems => {
          checkout.attrs.lineItems = lineItems

          return checkout
        })
    })
}

const addLineItems = (client, checkoutId, lineItems) => {
  return client.graphQLClient
    .send(checkoutAddMutation, { checkoutId, lineItems })
    .then(handleCheckoutMutation('checkoutLineItemsAdd', client.graphQLClient))
}

const updateLineItems = (client, checkoutId, lineItems) => {
  return client.graphQLClient
    .send(checkoutUpdateMutation, { checkoutId, lineItems })
    .then(
      handleCheckoutMutation('checkoutLineItemsUpdate', client.graphQLClient)
    )
}

const removeLineItems = (client, checkoutId, lineItemIds) => {
  return client.graphQLClient
    .send(checkoutRemoveMutation, { checkoutId, lineItemIds })
    .then(
      handleCheckoutMutation('checkoutLineItemsRemove', client.graphQLClient)
    )
}

const ContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    isCartLoading: true,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  }
  const [store, updateStore] = useState(initialStoreState)
  const [, setCookies] = useCookies([])

  const isRemoved = useRef(false)

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        updateStore(prevState => {
          return { ...prevState, checkout, isCartLoading: false }
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => getCheckoutById(store.client, id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved.current && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null)
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved.current) {
        setCheckoutInState(newCheckout)
      }
    }

    initializeCheckout()
  }, [store.client, store.client.checkout])

  useEffect(() => () => {
    isRemoved.current = true
  })

  useEffect(() => {
    const isCartLoading = store.isCartLoading
    const itemsLength = store.checkout.lineItems.length

    if (isCartLoading) {
      return
    }

    setCookies(
      'grizzli-gatsby-cart',
      {
        cartItems: itemsLength,
      },
      {
        path: '/',
        domain: '.grizzliwinery.com',
        expires: new Date(
          Date.now() + getSecondsSinceExpiration({ years: 1 }) * 1000
        ),
      }
    )
  }, [store.isCartLoading, store.checkout.lineItems.length, setCookies])

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (
          variantId,
          quantity,
          optionalRawBottleFeeVariantId
        ) => {
          if (variantId == null || variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.')
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          const { checkout, client } = store

          const checkoutId = checkout.id
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ]

          if (
            optionalRawBottleFeeVariantId != null &&
            optionalRawBottleFeeVariantId !== ''
          ) {
            const encodedBottleFeeGid = getEncodedBottleFeeGid(
              optionalRawBottleFeeVariantId
            )
            lineItemsToUpdate.push({
              variantId: encodedBottleFeeGid,
              quantity: parseInt(quantity, 10),
            })
          }

          return addLineItems(client, checkoutId, lineItemsToUpdate).then(
            checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false }
              })
            }
          )
        },
        removeLineItem: lineItemID => {
          const { checkout, client } = store

          return removeLineItems(client, checkout.id, [lineItemID]).then(
            res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })

              return res.lineItems
            }
          )
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ]

          return updateLineItems(client, checkoutID, lineItemsToUpdate).then(
            res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            }
          )
        },
        updateLineItems: async (checkoutId, lineItemsToUpdate) => {
          const newCheckout = await updateLineItems(
            client,
            checkoutId,
            lineItemsToUpdate
          )

          updateStore(prevState => {
            return { ...prevState, checkout: newCheckout }
          })

          return newCheckout.lineItems
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
