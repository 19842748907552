import React from 'react'
import PropTypes from 'prop-types'
import ContextProvider from '~/provider/ContextProvider'
import { CookiesProvider } from 'react-cookie'
import UserProvider from '../provider/UserProvider'
import NotificationProvider from '../provider/NotificationProvider'

const Layout = ({ children }) => {
  return (
    <CookiesProvider>
      <UserProvider>
        <NotificationProvider>
          <ContextProvider>{children}</ContextProvider>
        </NotificationProvider>
      </UserProvider>
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
