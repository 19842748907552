const getSecondsSinceExpiration = cookieExpiration => {
  if (typeof cookieExpiration === 'number') {
    return cookieExpiration
  }

  const SECONDS_IN_YEAR = 31536000
  const SECONDS_IN_DAY = 86400
  const SECONDS_IN_HOUR = 3600

  const _cookieExpiration = {
    years: 0,
    days: 0,
    hours: 0,
    ...cookieExpiration,
  }

  const { years, days, hours } = _cookieExpiration

  return (
    years * SECONDS_IN_YEAR + days * SECONDS_IN_DAY + hours * SECONDS_IN_HOUR
  )
}

export default getSecondsSinceExpiration
